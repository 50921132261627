<template>
  <div>
    <portal to="subheader_portal">
      <router-link :to="{ name: 'user_create' }">
        <b-button variant="success"
          ><b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
          Nuevo</b-button
        >
      </router-link>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <b-col lg="12" class="my-1">
              <b-form-group
                label="Buscar"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Escribe para buscar"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Limpiar</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-table
              :items="items"
              :fields="fields"
              :filter="filter"
              @filtered="onFiltered"
              head-variant="light"
              caption-top
            >
              <template #cell(groups)="data">
                <Dropdown4
                  :btnClass="'btn btn-light btn-sm font-size-sm font-weight-bolder dropdown-toggle text-dark-75'"
                  :btnText="'Grupos'"
                  :items="data.item.groups"
                ></Dropdown4>
              </template>
              <template #cell(is_active)="data">
                <toggle-button
                  v-model="data.item.is_active"
                  @change="deactivateUser(data.item)"
                  :value="data.item.is_active"
                  :labels="{ checked: 'ON', unchecked: 'OFF' }"
                  :color="{
                    checked: '#1BC5BD',
                    unchecked: '#F64E60',
                    disabled: '#CCCCCC',
                  }"
                />
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mb-2 mr-2"
                  variant="primary"
                  @click="editUser(data.item)"
                >
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "../../../core/services/api.service";
import Dropdown4 from "@/view/content/dropdown/Dropdown4.vue";

export default {
  components: {
    Dropdown4,
  },
  data() {
    return {
      fields: [
        { key: "username", label: "Usuario" },
        { key: "email", label: "Email" },
        { key: "full_name", label: "Nombres y apellidos" },
        { key: "is_active", label: "" },
        { key: "groups", label: "" },
        { key: "actions", label: "" },
      ],
      items: [],
      filter: null,
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    };
  },
  mounted() {
    this.getUsers();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Usuarios", route: "/core/user" },
    ]);
  },
  methods: {
    deactivateUser(user) {
      ApiService.delete(`core/user/${user.id}/`)
        .then(() => {
          this.$successToast();
        })
        .catch(() => {
          this.$errorToast();
        });
    },
    editUser(user) {
      this.$router.push({ name: "user_edit", params: { id: user.id } });
    },
    getUsers() {
      ApiService.get("core/user").then((res) => {
        console.log(res);
        this.items = res.data.results;
      });
    },
  },
};
</script>
